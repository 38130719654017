import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {Swiper, SwiperSlide} from 'swiper/react'
import {Autoplay, EffectFade, Pagination} from 'swiper'

import {
  Content,
  ContentButton,
  ContentCategories,
  ContentCategory,
  ContentDate,
  ContentTitle,
  LabelIcon,
  LabelLink,
  LabelText,
  Wrapper
} from './style'
import {Container, Overlay} from '@/components/HomeHero/Item/style'
import resolveAssetURL from '@/utils/resolveAssetURL'

const HeroJournal = ({
                       slides
                     }) => {
  return (
    <Wrapper>
      <Swiper
        modules={[Pagination, Autoplay, EffectFade]}
        pagination={{clickable: true}}
        loop
        autoplay = {{
          disableOnInteraction: false
        }}
        effect='fade'
        watchSlidesProgress
      >
        {
          slides.slice(0,3).map((slide, i) => {
            const {
              image,
              category,
              title,
              ctaTo
            } = slide
            const date = moment(slide.date).add(1, 'year').format('LL')
            return (
              <SwiperSlide key={`slide-${i}`}>
                <Container background={resolveAssetURL(image)}>
                  <Overlay>
                    <Content>
                      <ContentCategories>
                        <ContentCategory>
                          {category}
                        </ContentCategory>
                      </ContentCategories>
                      <ContentTitle>{title}</ContentTitle>
                      <ContentDate>{date}</ContentDate>
                      <ContentButton to={ctaTo}>
                        Learn More
                      </ContentButton>
                    </Content>
                  </Overlay>
                </Container>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </Wrapper>
  )
}

HeroJournal.propTypes = {
  slides: PropTypes.array.isRequired
}

export default HeroJournal
