import * as React from 'react'
import PropTypes from 'prop-types'
import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  Container,
  ContentActions,
  ContentSubtitle,
  ContentText,
  ContentTitle,
  ContentWrapper,
  Image,
  ImageWrapper,
  Wrapper
} from './style'

const FeaturedVideoBlock = ({
  title = '',
  titleTag = 'h3',
  subtitle = '',
  subtitleTag = 'span',
  text = '',
  image = '',
  alt = '',
  actions = []
}) => {
  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          { !!subtitle && (<ContentSubtitle { ...{ as: subtitleTag } }>{ subtitle }</ContentSubtitle>) }
          { !!title && (<ContentTitle { ...{ as: titleTag } }>{ title }</ContentTitle>) }
          { !!text && (<ContentText>{ text }</ContentText>) }
          {
              !!actions.length && (
                <ContentActions className={ actions.length > 1 ? 'many' : 'one' }>
                  { actions.map((action) => action) }
                  <Image loading='lazy' src='/images/play-arrow.svg' alt='play icon' />
                </ContentActions>
              )
            }
          {/* <VideoCta key={ 2 } onClick={ handleClick } target='_blank'> */}
          {/*   <VideoCtaIcon loading='lazy' src={ icon } alt='play icon' /> */}
          {/*   <VideoCtaLabel>{active.videoLabel}</VideoCtaLabel> */}
          {/* </VideoCta> */}
        </ContentWrapper>
        <ImageWrapper>
          <Image loading='lazy' src={ resolveAssetURL(image) } alt={ alt } />
        </ImageWrapper>
      </Wrapper>
    </Container>
  )
}

FeaturedVideoBlock.propTypes = {
  title: PropTypes.string,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  subtitle: PropTypes.string,
  subtitleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'span']),
  actions: PropTypes.array,
  text: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string
}

export default FeaturedVideoBlock
