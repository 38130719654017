import React, { useEffect, useState } from 'react'
import {
  CheckboxGroup,
  CheckboxInput,
  CheckboxLabel,
  Checkmark,
  Divider,
  FilterContainer,
  Select,
  SubTitle,
  Title
} from './style'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { isMobileState } from '@/recoil/layout'
import SelectFilter from '@/components/Forms/Inputs/Select'

const FiltersVertical = ({ filters, onSortChange, onFilterChange }) => {
  const [sortBy, setSortBy] = useState('latest')
  const [formattedFilters, setFormattedFilters] = useState(
    filters.filter(({ label }) => label !== 'All')
  )
  const [selectedIds, setSelectedIds] = useState([])
  const isMobile = useRecoilValue(isMobileState)

  const handleSortChange = (event) => {
    setSortBy(event.target.value)
    onSortChange(event.target.value)
  }

  const handleFilterChange = (event) => {
    if (isMobile) {
      const selectedFilter = filters.find((filter) => filter.label === event)?.catId
      return onFilterChange(selectedFilter)
    } else {
      const { checked, name } = event.target
      const id = filters.find((filter) => filter.label === name)?.catId
      if (checked) {
        return setSelectedIds((ids) => [...ids, id])
      } else {
        return setSelectedIds((ids) => ids.filter((existingId) => existingId !== id))
      }
    }
  }

  useEffect(() => {
    onFilterChange(selectedIds.length === 0 ? 'all' : selectedIds)
  }, [selectedIds])

  return (
    <FilterContainer id='filter__container'>
      {!isMobile
        ? (
          <>
            <Title>Sort by</Title>
            <Select value={ sortBy } onChange={ handleSortChange }>
              <option value='latest'>Latest</option>
              <option value='oldest'>Oldest</option>
              <option value='popular'>Popular</option>
            </Select>
            <Divider />
            <Title>Filter by</Title>
            <CheckboxGroup>
              <SubTitle>Interests</SubTitle>
              {
              formattedFilters.map(({ label, catId }, i) => (
                <CheckboxLabel key={ i }>
                  <CheckboxInput
                    type='checkbox'
                    id={ catId }
                    name={ label }
                    onChange={ handleFilterChange }
                  />
                  <Checkmark />
                  {label}
                </CheckboxLabel>
              ))
            }
            </CheckboxGroup>
          </>
          )
        : (
          <>
            <SelectFilter
              label='Sort by'
              list={ ['Latest', 'Oldest', 'Popular'] }
              onChange={ onFilterChange }
              name='sortSelect'
              withDropDownIcon
              isFilter
            />
            <SelectFilter
              label='Filter by'
              list={ filters.map(({ label }) => label) }
              onChange={ onFilterChange }
              name='filterSelect'
              withDropDownIcon
              isFilter
            />
          </>
          )}
    </FilterContainer>
  )
}

FiltersVertical.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    catId: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['enabled', 'disabled'])
  })).isRequired,
  onSortChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired
}

export default FiltersVertical
