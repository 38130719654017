import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'
import { Container, Wrapper } from '@/components/Forms/Inputs/Select/style'

export const FilterContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  ${mediaMax.xs} {
    position: static;
    display: flex;
    gap: 2rem;
    width: 100%;
    padding: 2rem 0;

    ${Container} {
      flex: 1;
      height: 4.4rem;
      border: 1px solid ${({ theme }) => theme.colors.brown};
      border-radius: 0;

      ${Wrapper} {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 0;

        label {
          font-size: 1.2rem !important;
          font-weight: 600;
          color: ${({ theme }) => theme.colors.brown};
          text-transform: uppercase;
          letter-spacing: 0.02rem
        }

        div {
          transform: translateY(-0.3rem) rotate(0deg);
        }

        &:after {
          all: unset;
          display: none;
        }
      }
    }
  }
`

export const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  color: #8C8A89;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
`

export const SubTitle = styled.h4`
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.02rem;
`

export const Divider = styled.div`
  border-top: 1px solid #B3B1B0;
  margin-top: 3rem;
  margin-bottom: 3.6rem;
`

export const Select = styled.select`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: -0.02rem;
  border: 0;
  margin-top: 1.5rem;
  pointer-events: all;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  letter-spacing: -0.02rem;
  margin-top: 2rem;
  user-select: none;
  pointer-events: all;
  cursor: pointer;
`

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`

export const Checkmark = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  border: 2px solid ${({ theme }) => theme.colors.black};
  border-radius: 2px;
  margin-right: 1.2rem;

  ${CheckboxInput}:checked + & {
    background-color: ${({ theme }) => theme.colors.green};
    border-color: ${({ theme }) => theme.colors.green};
  }

  ${CheckboxInput}:checked + &::after {
    content: '✓';
    color: white;
    font-size: 12px;
  }
`
