import React from 'react'

import { Wrapper } from './style'
import CardGallery from '@/components/CardGallery'
import PropTypes from 'prop-types'
import Button from '@/components/Button'

const JournalCardBlock = ({ items, filters }) => {
  return (
    <Wrapper>
      <CardGallery
        title='Art of Entertaining'
        items={ items }
        filters={ filters }
        action={ <Button key={ 1 }>Load More</Button> }
      />
    </Wrapper>
  )
}

JournalCardBlock.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    ref: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number
  })),
  filters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    catId: PropTypes.string
  }))
}

export default JournalCardBlock
