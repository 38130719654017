import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { GridContainer } from '@/components/Grid'

import {
  ActionWrapper,
  CardButton,
  CardGalleryWrapper,
  CardLink,
  CardTitle,
  CardWrapper,
  Container,
  FilterWrapper,
  Title,
  ViewerWrapper,
  ViewVideo,
  Wrapper
} from './style'
import { Image, ImageWrapper } from '@/components/Block/Sliders/MediaSlider/MediaCard/style'

import resolveAssetURL from '@/utils/resolveAssetURL'
import FiltersVertical from '@/components/FiltersVertical'
import FeaturedVideoBlock from '@/components/FeaturedVideoBlock'
import Button from '@/components/Button'
import VideoPopup from '@/components/Popup/VideoPopup'
import { Actions } from '@/components/Block/Text/style'
import { CloseButton, Viewer } from '@/components/ImageTextPortrait/style'

const CardGallery = ({ title = '', items, filters = [], action }) => {
  const [data, setData] = useState(items)
  const [hasBlock, setHasBlock] = useState(false)
  const [displayCount, setDisplayCount] = useState(18)
  const [isOpen, setIsOpen] = useState(false)

  const loadMore = () => {
    setDisplayCount(prevCount => Math.min(prevCount + 18, data.length))
  }

  const filterData = (activeFilters) => {
    let hasBlock = true
    let filteredResults = items

    if (!activeFilters.includes('all')) {
      filteredResults = items.filter((item) => activeFilters.includes(item.catId))
      hasBlock = false
    }

    setHasBlock(hasBlock)
    setData(filteredResults)
  }

  const filterSortData = (sortBy) => {
    const sortedData = [...data]

    if (sortBy === 'latest') {
      sortedData.sort((a, b) => new Date(b.date) - new Date(a.date))
    } else if (sortBy === 'oldest') {
      sortedData.sort((a, b) => new Date(a.date) - new Date(b.date))
    } else if (sortBy === 'popular') {
      // sortedData.sort((a, b) => b.popularity - a.popularity);
    }

    setData(sortedData)
  }

  // TODO: A revoir pour le sticky filter
  const wrapperRef = useRef(null)
  const filterWrapperRef = useRef(null)
  const filterContainerRef = useRef(null)

  useEffect(() => {
    const wrapper = wrapperRef.current
    const filterWrapper = filterWrapperRef.current
    filterContainerRef.current = wrapper.querySelector('#filter__container')

    const handleScroll = () => {
      if (window.innerWidth <= 768) return

      const wrapperRect = wrapper.getBoundingClientRect()
      // const filterWrapperRect = filterWrapper.getBoundingClientRect()

      if (wrapperRect.top < 0) {
        const maxTranslateY = wrapper.scrollHeight - filterWrapper.offsetHeight
        const translateY = Math.min(-wrapperRect.top, maxTranslateY)
        filterContainerRef.current.style.transform = `translateY(${translateY}px)`
        console.log('translateY', translateY)
      } else {
        filterContainerRef.current.style.transform = 'translateY(0)'
      }
    }

    document.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)

    return () => {
      wrapper.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [])

  return (
    <Container>
      <GridContainer>
        {!!title && <Title>{ title }</Title>}
        <Wrapper ref={ wrapperRef }>
          {!!filters.length &&
            <FilterWrapper ref={ filterWrapperRef }>
              <FiltersVertical
                filters={ filters }
                onSortChange={ filterSortData }
                onFilterChange={ filterData }
              />
            </FilterWrapper>}
          <CardGalleryWrapper id='card__gallery'>
            {data.slice(0, displayCount).map((item, index) => {
              const content = [
                <CardWrapper key={ `card-${index}` }>
                  <CardLink to={ item.url } title={ item.title }>
                    <ImageWrapper>
                      <Image loading='lazy' src={ resolveAssetURL(item.image) } alt={ item?.imageAlt } />
                      <CardButton onClick={ () => setIsOpen(true) }>
                        <Image src='/images/play-button.svg' alt='open icon' />
                      </CardButton>
                    </ImageWrapper>
                    <CardTitle>{item.title}</CardTitle>
                  </CardLink>
                </CardWrapper>
              ]
              if ((index + 1) % 9 === 0 && hasBlock) {
                content.push(
                  <FeaturedVideoBlock
                    key={ `highlight-${index}` }
                    subtitleTag='span'
                    subtitle='Featured Video'
                    titleTag='h2'
                    title='Eugenia Diaz hosts a dreamy sunset dinner from the heart of her garden'
                    text='Inspiration'
                    image='/images/background.jpg'
                    actions={ [
                      <Button key={ 1 } to='/'>WATCH VIDEO</Button>
                    ] }
                  />
                )
              }
              return content
            })}
            {displayCount < data.length && (
              <ActionWrapper>
                <Actions className='one' onClick={ loadMore }>{action}</Actions>
              </ActionWrapper>
            )}
          </CardGalleryWrapper>
        </Wrapper>
        <VideoPopup />
        <Viewer isOpen={ isOpen }>
          <ViewerWrapper>
            {/* <ViewerImage loading='lazy' src='/images/coming-soon-bg.jpg' alt='/' /> */}
            <ViewVideo controls autoPlay>
              <source src='/videos/Product/video_1.mp4' type='video/mp4' />
            </ViewVideo>
          </ViewerWrapper>
          <CloseButton onClick={ () => setIsOpen(false) }>
            <Image src='/images/close-button.svg' alt='close icon' />
          </CloseButton>
        </Viewer>
      </GridContainer>
    </Container>
  )
}

CardGallery.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    ref: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number
  })),
  filters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    catId: PropTypes.string
  })),
  action: PropTypes.node
}

export default CardGallery
